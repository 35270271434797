import React from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import "./About.css";

function History() {
  return (
    <div className="about-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">About Us</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">About Us</h2>
            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              Pt. Ram Kedar Ram Kishore Tripathi P.G.College is managed by Pt
              Ram Kedar Ram Kishore Tripathi Sikhsha Sansthan Society,
              Sultanpur. This Society was founded under the president-ship of
              Dr.Om Prakash Tripathi.
              <br />
              <br />
              The college is situated 20 km far from Sultanpur City 5 km before
              of Aligang Market There was no any College in the very rural area.
              Students of this area, especially girls, faced difficulties in
              getting admission to colleges.
              <br />
              <br />
              The college aims at equipping students with a moral and
              intellectual outlook and inculcating in them the highest
              principles of unity and national integration.
              <br />
              <br />
              The college attaches great importance to the dissemination of
              learning and an all round development of the personality of its
              students.All efforts are made to create a congenial atmosphere
              especially for female students and every possible facility for
              proper education is provided to them.
              <br />
              <br />
              The College is affiliated to the Dr Ram Manohar Lohia Awadh
              University of Faizabad for teaching courses leading to B.A. and
              B.Sc. degree examinations.The College is also recognized for
              registration of students for Post-Graduation in the subjects of
              Hindi, Sociology, Education, and Home Science Studies.
              <br />
              <br />
              The College has a well-qualified, experienced and committed
              teaching faculty. Our faculty is interacting with students
              directly and they will know each student by name and better yet,
              will know the students’ strengths and weaknesses. extra-curricular
              activities includingScout & Guide ,NSS, Cultural Programesand
              Sports, etc., the performance of students is excellent
            </p>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/history.jpg"
              className="img-fluid h-50 w-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default History;
