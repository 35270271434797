import React from "react";
import "./Facilities.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";

function Nss() {
  return (
    <div className="facility-page">
      <Header />
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">NSS(NATIONAL SERVICE SCHEME)</h2>
            <p style={{ textAlign: "justify" }}>
              The National Service Scheme was started by Goverment of India,
              Ministry of Education and Culture,Govt of India , Incollabration
              with State Goverment in the year 1969 in 37 selected Universities.
              Our university has been participating in the scheme right from its
              inception. N.S.S in which the students learn dignity of labour and
              the importance of social service. The students get involved in
              various activities of community development during the seven-day
              camp. NSS provides diversified opportunities to students to
              develop their personality through community service. There are 2
              units of the NSS in the college.
            </p>

            <h4 className="mb-4 mb-lg-5">Aim and Objectives</h4>
            <p style={{ textAlign: "justify" }}>
              <ul>
                <li>Understand the community in which they work.</li>
                <li>Understand themselves in relation to their community</li>
                <li>
                  Identify the needs and problems of the community and involve
                  them in problem solving process
                </li>
                <li>
                  Develop among themselves a sense of social and civic
                  responsibility
                </li>
                <li>
                  Utilize their knowledge in finding practical solutions to
                  individual and community problems
                </li>
                <li>
                  Develop competence required for group living and sharing of
                  responsibilities
                </li>
                <li>
                  Gain skills in mobilizing community participation Motto of NSS
                  "Not Me But You".
                </li>
              </ul>
            </p>
          </div>

          <div className="col-lg-6 d-flex justify-content-center">
            <img src="./img/nss.jpg" className="img-fluid h-50" alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Nss;
