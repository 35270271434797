import React from "react";
import "./Course.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";

function Undergraduate() {
  return (
    <div className="course-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Courses</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-8 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5" style={{ color: "#ec2328" }}>
              UNDER GRADUATE (U.G.)
            </h2>

            <h4 style={{ color: "#ec2328" }}>Course Overview</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              Undergraduate course can be perused by only those candidates who
              have acquired higher school certificate course in simple word
              candidate who have cleared 10+2. Bachelor degree course can be
              studied in a variety of academic stream like Arts,Commerce,Science
              etc.Graduate course mostly like three-yearduration.Bachelor course
              lays foundation for one'ss desired path of carrier .Upon the
              completion of bachelor degree a candidate is known as Graduate and
              receive the bachelor degree in the stream he/she opted for
              ,e.g.B.A degree -Bachelor of Arts degree means that a student has
              completed a graduate course in Arts .
            </p>

            <h4 style={{ color: "#ec2328" }}>Subjects Available</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              <b>Bachelor of Arts:</b>
              <div></div>
              <br />
              <ul className="row ug ps-0">
                <li className="col-3 li">ENGLISH</li>
                <li className="col-3 li">GEOGRAPHY</li>
                <li className="col-3 li">HINDI</li>
                <li className="col-3 li">HOME SCIENCE</li>
                <li className="col-3 li">PHYSICAL EDUCATION</li>
                <li className="col-3 li">POLITICAL SCIENCE</li>
                <li className="col-3 li">SANSKRIT</li>
                <li className="col-3 li">SOCIOLOGY</li>
              </ul>
            </p>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              <b>Bachelor of Science:</b>
              <div></div>
              <br />
              <ul className="row ug ps-0">
                <li className="col-3 li">BOTANY</li>
                <li className="col-3 li">CHEMISTRY</li>
                <li className="col-3 li">MATHEMATICS</li>
                <li className="col-3 li">PHYSICS</li>
                <li className="col-3 li">ZOOLOGY</li>
              </ul>
            </p>
            <h4 style={{ color: "#ec2328" }}>Duration:</h4>
            <p>
              It takes three or four years to complete an "undergraduate"
              degree. The three-year undergraduate programs are mostly in the
              fields of arts, commerce, science etc., and the four-year programs
              are mostly in the fields of technology, engineering,
              pharmaceutical sciences, agriculture etc.
            </p>

            <h4 style={{ color: "#ec2328" }}>Eligibility</h4>
            <p>
              The admission is granted on the basis of the Intermediate
              Examination or equivalent examination.
            </p>

            <h4 style={{ color: "#ec2328" }}>Admission Procedure</h4>
            <p>
              Admissions will be strictly on merit basis.Seats will be allotted
              on the merit and as per the direction of the Govt. and University
              as well as first come first served basis.
            </p>

            <h4 style={{ color: "#ec2328" }}>Course Fees:</h4>
            <p>
              The annual fee For B.A./B.Sc./B.Com/ is according to the Dr Ram
              Manohar Lohia Avadh University, Ayodhya norms which can be
              deposited in two instalments.
            </p>

            <h4 style={{ color: "#ec2328" }}>Total Seat :</h4>
            <p>
              Bachelor Of Arts ( B.A. ) 960 Seats
              <br />
              Bachelor Of Science ( B.Sc ) 420 Seats
            </p>
          </div>
          <div className="col-lg-4 d-flex justify-content-center">
            <img
              src="./img/history.jpg"
              className="img-fluid h-25 w-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Undergraduate;
