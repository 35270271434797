import React from "react";
import "./Student.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import Card from "react-bootstrap/Card";

function Feedback() {
  return (
    <div className="course-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Student Feedback</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-12 d-flex flex-column text-center justify-content-center mb-4 mb-lg-0">
            <h2 className=" " style={{ color: "#ec2328" }}>
              Student Feedback
            </h2>

            <p
              className="mb-4 mb-lg-5"
              style={{ textAlign: "justify", justifyContent: "center" }}
            >
              <div>
                <Card
                  className="col-6 mx-auto complaint-card"
                  style={{ margin: "auto" }}
                >
                  <Card.Body>
                    <Card.Title>
                      Fill the form to submit your feedback
                    </Card.Title>

                    <Card.Text>
                      <form>
                        <input
                          type="text"
                          placeholder="Student Name"
                          className="grievance-form"
                        />
                        <br />
                        <input
                          type="phone"
                          name=""
                          id=""
                          placeholder="Contact Number"
                          className="grievance-form"
                        />
                        <br />
                        <input
                          type="text"
                          name=""
                          id=""
                          placeholder="Admission No."
                          className="grievance-form"
                        />
                        <br />
                        <textarea
                          name=""
                          id=""
                          cols="20"
                          rows="10"
                          className="grievance-form"
                          placeholder="Type your feedback message here!"
                        ></textarea>
                      </form>
                    </Card.Text>
                    <Card.Link href="#" className="btn btn-danger">
                      Submit
                    </Card.Link>
                  </Card.Body>
                </Card>
              </div>
              <br />
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Feedback;
