import './App.css';
import { Link, Routes, Route } from 'react-router-dom';

import Home from './pages/Home/Home';
import Courses from './pages/Courses/Courses';
import SmartClass from './pages/Facilities/SmartClass';
import Lab from './pages/Facilities/Lab';
import Library from './pages/Facilities/Library';
import Sports from './pages/Facilities/Sports';
import Nss from './pages/Facilities/Nss';
import ComputerCenter from './pages/Facilities/ComputerCenter';
import CollegeCampus from './pages/Facilities/CollegeCampus';
import SwayamPrabha from './pages/Facilities/SwayamPrabha';
import Gallery from './pages/Gallery';

import History from './pages/About/History';
import Mission from './pages/About/Mission&Vision';
import Objective from './pages/About/Objective';
import Kulgeet from './pages/About/Kulgeet';

import Contact from './pages/Contact/Contact';
import BEd from './pages/Courses/BEd';
import DelEd from './pages/Courses/DelEd';
import Undergraduate from './pages/Courses/Undergraduate';
import Postgraduate from './pages/Courses/Postgraduate';
import AdmissionProcedure from './pages/Admission/AdmissionProcedure';
import RulesofCollege from './pages/Admission/RulesofCollege';
import RulesofLibrary from './pages/Admission/RulesofLibrary';
import TalentSearchResult from './pages/Result/TalentSearch';
import Certificate from './pages/Result/Certificate';
import ManagerMsg from './pages/About/ManagerMsg';
import PrincipalMsg from './pages/About/PrincipalMsg';
import StudentData from './pages/Students/StudentData';
import Attendance from './pages/Students/BiometricAttendaance';
import Faculty from './pages/Faculty/Faculty';
import Admission from './pages/Admission/Admission';
import Grievances from './pages/Students/Grievances';
import Feedback from './pages/Students/Feedback';
import Examination from './pages/Students/Examination';

function App() {
  return (
    <div>


      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/smartClass' element={<SmartClass />} />
        <Route path='/lab' element={<Lab />} />
        <Route path='/library' element={<Library />} />
        <Route path='/sports' element={<Sports />} />
        <Route path='/nss' element={<Nss />} />
        <Route path='/computer-center' element={<ComputerCenter />} />
        <Route path='/college-campus' element={<CollegeCampus />} />
        <Route path='/courses' element={<Courses />} />
        <Route path='/about-us' element={<History />} />
        <Route path='/manager-msg' element={<ManagerMsg />} />
        <Route path='/principal-msg' element={<PrincipalMsg />} />

        <Route path='/swyamPrabha' element={<SwayamPrabha />} />
        <Route path='/gallery' element={<Gallery />} />

        <Route path='/BEd' element={<BEd />} />
        <Route path='/DelEd' element={<DelEd />} />
        <Route path='/undergraduate' element={<Undergraduate />} />
        <Route path='/postgraduate' element={<Postgraduate />} />

        <Route path='/examination' element={<Examination />} />
        <Route path='/student-data' element={<StudentData />} />
        <Route path='/biometric-attendance' element={<Attendance />} />
        <Route path='/online-student-grievances' element={<Grievances />} />
        <Route path='/online-student-feedback' element={<Feedback />} />

        <Route path='/faculty-data' element={<Faculty />} />

        <Route path='/admission' element={<Admission />} />


        <Route path='/contact' element={<Contact />} />
        <Route path='/mission&vision' element={<Mission />} />
        <Route path='/objective' element={<Objective />} />
        <Route path='/kulgeet' element={<Kulgeet />} />

        <Route path='/talent-search-result-2024' element={<TalentSearchResult />} />
        <Route path='/certificate/:roll' element={<Certificate />} />
      </Routes>


    </div>
  );
}

export default App;
