import React from "react";
import "./Faculty.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";

function Faculty() {
  return (
    <div className="course-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Faculty Data</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-8 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5" style={{ color: "#ec2328" }}>
              Faculty Data
            </h2>

            <h4 style={{ color: "#ec2328" }}>Search Faculty Data</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              <div></div>
              <br />
              <ul className="row ug ps-0">
                <li className="col-5 li">B.ED</li>
                <li className="col-5 li">D.EL.ED</li>
              </ul>
            </p>
          </div>
          <div className="col-lg-4 d-flex justify-content-center">
            <img src="./img/history.jpg" className="img-fluid h-75" alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faculty;
