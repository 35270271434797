import React from "react";
import "./About.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";

function Objective() {
  return (
    <div className="about-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">College Campus</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">College Campus</h2>
            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              A modern, well equipped infrastructure is provided to all courses
              to facilitate better learning. The college has refurbished lecture
              rooms and laboratories. This makes the teaching - learning process
              student-friendly and interactive. It has multimedia rooms, a
              swanky Auditorium, Laboratory, huge airy Library with a rich
              collection of books, journals and periodicals and a airy Sports
              room.
            </p>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/history.jpg"
              className="img-fluid h-75 w-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Objective;
