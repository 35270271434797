import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

function ChooseSection() {
  return (
    <div>
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-12">
            <Card className="d-flex align-items-center border-0 h-100">
              <Card.Body>
                <Card.Title className="text-center text-capitalize mb-3">
                  <h2
                    className="text-center mb-5 px-2 mx-0 "
                    style={{
                      color: "#c81f1f",
                      borderBottom: "solid #6b1717 2px ",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    Pandit Ram Kedar Ram Kishore Tripathi Mahavidyalya{" "}
                  </h2>
                </Card.Title>
                <Card.Text className="text-center">
                  <p className="px-2 mx-0" style={{ textAlign: "justify" }}>
                    Pt. Ram Kedar Ram Kishore Tripathi P.G.College is managed by
                    Pt Ram Kedar Ram Kishore
                    TripathiSikhshanSansthanSocitey,Sultanpur.This Society was
                    founded under the presidentship of Dr.Om Prakash Tripathi .
                    The college is situated 20 km away from SultanpurCity 5 km
                    before Aligang Market. There was no College in the nearby
                    rural area. Students of this area, particularly girls, faced
                    difficulties in getting admission to colleges.
                    <br />
                    <span className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                      <Link to="/about-us">
                        <button
                          type="button"
                          className="btn btn-danger btn-sm mx-0 mx-sm-2 my-2 my-sm-0"
                        >
                          Know more
                        </button>
                      </Link>
                    </span>
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          {/* <div className="col-lg-4">
            <Card
              className="d-flex align-items-center  h-100"
              style={{
                backgroundColor: "grey",
                color: "white",
                border: "solid 14px #2f2f30",
                fontFamily: "Caveat Brush, cursive",
              }}
            >
              <div className="mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="white"
                  class="bi bi-clipboard-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2"
                  />
                </svg>
              </div>
              <Card.Body>
                <Card.Title
                  className="text-center text-capitalize mb-3"
                  style={{
                    fontFamily: "Caveat Brush, cursive",
                    fontSize: "25px",
                  }}
                >
                  <u>Notice Board</u>
                </Card.Title>
                <Card.Text className="text-center">
                  <p
                    className="px-2 mx-0"
                    style={{
                      textAlign: "justify",
                      fontFamily: " cursive",
                      fontSize: "14px",
                    }}
                  >
                    Talent Search Examination 2024 prize distribution ceremony
                    scheduled to be held on 14-02-2024 has been postponed due to
                    University Examination, it will be happening soon.
                    <br />
                    Kindly visit our website regularly for current updates.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ChooseSection;
