import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import ChooseSection from "../../components/ChooseSection/ChooseSection";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import { Card, Button } from "react-bootstrap";
import Blog1Img from "../../images/blog1-img.jpg";
import Blog2Img from "../../images/blog2-img.jpg";
import Blog3Img from "../../images/blog3-img.jpg";
import logoImage from "../../images/ptrkt-logo.png";
import Slider from "../../components/Slider/Slider";

const blogs = [
  {
    id: 1,
    img: [Blog1Img],
    title: "Smart Classrooms",
    description:
      "Our college has individual and well-equipped smart classroom for each department. The smart classroom helps to renovate the traditional education system into modernized one. It provides eminence education to students through better concept formation, concept elaboration, improvement in reading skills and academic attainment.",
  },
  {
    id: 2,
    img: [Blog2Img],
    title: "Laboratories",
    description:
      "Our College Provides modern and sophisticated teaching facilities that are constantly upgraded. Each department at the institute boasts of an array of highly advanced laboratories that are sufficiently equipped with the latest instruments and apparatus. The aim is to provide students a conceptual as well as practical understanding of the subject through hands-on training.",
  },
  {
    id: 3,
    img: [Blog3Img],
    title: "Sports",
    description:
      "Sport is an integral part of the curriculum. Various sports facility is provided to the students within the campus. The College is committed to create a balanced atmosphere of academic, cultural and sports activities for the overall personality development of its students. Talented students are honored with medals, trophies and certificates.",
  },
];

function Home() {
  return (
    <div className="home-page">
      <Header />

      <header className="h-80 min-vh-100 d-flex justify-content-center align-items-center text-light">
        <div className="container d-flex justify-content-center flex-column align-items-center ">
          <img src={logoImage} width="90" height="90" />
          <h2>Welcome To</h2>
          <h2 className="text-center fw-bold ptrkt">
            Pandit Ram Kedar Ram Kishore Tripathi Mahavidyalaya
          </h2>

          <div className="d-flex flex-column flex-sm-row align-items-center">
            <Link to="/courses">
              <button
                type="button"
                className="btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0"
              >
                Our Courses
              </button>
            </Link>
            <Link to="/contact">
              <button
                type="button"
                className="btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0"
              >
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </header>

      <div className="py-5">
        <ChooseSection />
      </div>

      <div
        className="py-2"
        style={{ backgroundColor: "rgba(192, 192, 193, 0.645)" }}
      >
        <div className="container">
          <div className="row d-flex align-items-center justify-content-around">
            <div className="col-lg-5">
              <h2 className="text-capitalize">Apply Online</h2>
              <p>
                Acharya Chanakya Mahavidyalaya invites you to join our
                Undergraduate and Postgraduate Courses.
              </p>
              <Link to="https://online.acmcollege.org/">
                <button
                  type="button"
                  className="btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0"
                >
                  Apply Online
                </button>
              </Link>
            </div>
            <div className="col-lg-5 mt-5 mt-lg-0">
              <img
                src="./img/smart-classroom-acm.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="py-5">
            <FaqAccordion />
        </div> */}
      <br />
      <div className="blog-section text-light py-5 fl">
        <div className="container d-flex flex-column align-items-center">
          <h2 className="text-center text-capitalize mb-5">Our Facilities</h2>
          <div className="row g-4">
            {blogs.map((blog) => (
              <div key={blog.id} className="col-md-6 col-lg-4">
                <Link to="/" className="text-decoration-none">
                  <Card className="h-100 shadow ">
                    <Card.Img variant="top" src={blog.img} />
                    <Card.Body
                      className="p-md-3"
                      style={{ textAlign: "justify" }}
                    >
                      <Card.Title>{blog.title}</Card.Title>
                      <Card.Text>{blog.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            ))}
          </div>
          <Link to="/">
            <a className="btn btn-danger btn-lg mt-5" href="#" role="button">
              View More
            </a>
            {/* <button type='button' className='btn btn-danger btn-lg mt-5'>View More</button> */}
          </Link>
        </div>
      </div>
      <br />
      <Slider />
      <br />

      <div
        className="text-dark py-3 "
        style={{ backgroundColor: "rgba(192, 192, 193, 0.645)" }}
      >
        <div className="container d-flex flex-column align-items-center">
          <div className="row g-4 justify-content-between">
            <div className="col-md-6 col-lg-4">
              <Card className=" shadow ">
                <Card.Img
                  className="h-100 w-100 img-card"
                  variant="top"
                  src="./img/foundermanager.png"
                />
                <Card.Body className="p-md-3" style={{ textAlign: "justify" }}>
                  <Card.Title style={{ fontSize: "16px", textAlign: "center" }}>
                    Dr. Om Prakash Tripathi
                    <br />
                    Manager
                  </Card.Title>
                  <Card.Text style={{ fontSize: "12px", textAlign: "center" }}>
                    Acharya Chanakya Mahavidyalaya Sultanpur
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-6 col-lg-4">
              <Card className=" shadow c2">
                <Card.Img
                  className="h-100 w-100 img-card"
                  variant="top"
                  src="./img/principal.jpg"
                />
                <Card.Body className="p-md-3" style={{ textAlign: "justify" }}>
                  <Card.Title style={{ fontSize: "16px", textAlign: "center" }}>
                    Dr. B. P. Pandey
                    <br />
                    <br />
                    Principal
                  </Card.Title>

                  <Card.Text style={{ fontSize: "12px", textAlign: "center" }}>
                    Acharya Chanakya Mahavidyalaya Sultanpur
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* ))} */}
          </div>
        </div>
      </div>

      <div className="container d-flex flex-column map-contact">
        <div className="row py-4 px-2 h-100">
          <h3 className="contact-us"> Contact Us </h3>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 h-75 ">
            <Card className="map-card h-100">
              <iframe
                className="h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3576.17773508328!2d81.90842947541918!3d26.32074367700495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399a66e4ffffffff%3A0xae936b044624f0ef!2sPt%20Ram%20Kedar%20Ram%20Kishore%20Tripathi%20Maha%20Vidiyalaya%20Rawaniya%20Paschim!5e0!3m2!1sen!2sin!4v1711001313794!5m2!1sen!2sin"
                // style="border:0;"
                // allowfullscreen=""
                loading="lazy"
                // referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Card>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <form className="d-flex row">
              <div className="col-6 my-2">
                <input
                  type="text"
                  id="f-name"
                  placeholder=" First Name"
                  className="input"
                />
              </div>

              <div className="col-6 my-2">
                <input type="text" placeholder=" Last Name" className="input" />
              </div>

              <div className="col-12 my-2">
                <textarea
                  type="text-area"
                  placeholder=" Message"
                  className="input"
                />
              </div>

              <Button className="btn form-btn">Submit</Button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
