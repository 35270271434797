import React from "react";
import "./Slider.css";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

function Slider() {
  return (
    <div className="slider-gal">
      <div class="CSSgal">
        {/* <!-- Don't wrap targets in parent --> */}
        <s id="s1"></s>
        <s id="s2"></s>
        <s id="s3"></s>
        <s id="s4"></s>

        <div class="slider">
          <div className="quote-slide my-auto">
            <h2>Slide 1</h2>
            <p className="quote-para mx-4">
              Mankombu Sambasivan Swaminathan is an Indian geneticist and
              international administrator, renowned for his leading role in
              India's "Green Revolution," a program under which high-yield
              varieties of wheat and rice seedlings were planted in the fields
              of poor farmers. Swaminathan is known as "Indian Father of Green
              Revolution" for his leadership and success in introducing and
              further developing high-yielding varieties of wheat in India. He
              is the founder and chairman of the MS Swaminathan Research
              Foundation. Swaminathan is an advocate of moving India to
              sustainable development, especially using environmentally
              sustainable agriculture, sustainable food security and the
              preservation of biodiversity, which he calls an "evergreen
              revolution."
            </p>
          </div>
          <div className="quote-slide my-auto">
            <h2>Slide 2</h2>
            <p className="quote-para mx-4">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Laboriosam reiciendis, animi molestias error dolor atque
              praesentium illo nihil quibusdam est consequatur voluptate non
              quis ipsam nisi perspiciatis nobis totam quasi?
            </p>
          </div>
          <div className="quote-slide my-auto">
            <h2>Slide 3</h2>
            <p className="quote-para mx-4">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Laboriosam reiciendis, animi molestias error dolor atque
              praesentium illo nihil quibusdam est consequatur voluptate non
              quis ipsam nisi perspiciatis nobis totam quasi?
            </p>
          </div>
          <div className="quote-slide my-auto">
            <h2>Slide 4</h2>
            <p className="quote-para mx-4">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Laboriosam reiciendis, animi molestias error dolor atque
              praesentium illo nihil quibusdam est consequatur voluptate non
              quis ipsam nisi perspiciatis nobis totam quasi?
            </p>
          </div>
        </div>

        {/* <div class="prevNext">
          <div>
            <a href="#s4"></a>
            <a href="#s2"></a>
          </div>
          <div>
            <a href="#s1"></a>
            <a href="#s3"></a>
          </div>
          <div>
            <a href="#s2"></a>
            <a href="#s4"></a>
          </div>
          <div>
            <a href="#s3"></a>
            <a href="#s1"></a>
          </div>
        </div> */}

        <div class="bullets">
          <a href="#s1">1</a>
          <a href="#s2">2</a>
          <a href="#s3">3</a>
          <a href="#s4">4</a>
        </div>
      </div>
    </div>
  );
}

export default Slider;
