import React from "react";
import "./Student.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

function Examination() {
  return (
    <div className="course-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Examination</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-12 d-flex flex-column text-center justify-content-center mb-4 mb-lg-0">
            <h2 className=" " style={{ color: "#ec2328" }}>
              Examination
            </h2>

            <p
              className="mb-4 mb-lg-5"
              style={{ textAlign: "justify", justifyContent: "center" }}
            >
              <div>
                <Card
                  className="col-12 mx-auto complaint-card d-flex"
                  style={{ margin: "auto" }}
                >
                  <Card.Body>
                    <Form className="row">
                      <div className="col-3 ">
                        <Form.Select>
                          <option> Select Course</option>
                        </Form.Select>
                      </div>

                      <div className="col-3 ">
                        <Form.Select>
                          <option> Select Part</option>
                        </Form.Select>
                      </div>

                      <div className="col-3 ">
                        <Form.Select>
                          <option> Select Session</option>
                        </Form.Select>
                      </div>

                      <Card.Link
                        href="#"
                        className=" col-3 btn btn-sm btn-danger align-item-center"
                      >
                        Submit
                      </Card.Link>
                    </Form>
                    <br />
                  </Card.Body>
                </Card>
              </div>
              <br />

              {/* table */}

              <div>
                <Card
                  className="col-12 mx-auto complaint-card d-flex"
                  style={{ margin: "auto" }}
                >
                  <Card.Body>
                    <Table className="row">
                      <thead>
                        <tr className="col-12">
                          <th className="col-1">Sr. No.</th>
                          <th className="col-2">Roll No.</th>
                          <th className="col-2">Name</th>
                          <th className="col-2">Father's Name</th>
                          <th className="col-2">Total Marks</th>
                          <th className="col-2">Marks Obtained</th>
                          <th className="col-1">Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="col-12">
                          <td className="col-1">1</td>
                          <td className="col-2">1234</td>
                          <td className="col-2">abcd</td>
                          <td className="col-2">xyz</td>
                          <td className="col-2">900</td>
                          <td className="col-2">900</td>
                          <td className="col-1">Percentage</td>
                        </tr>
                      </tbody>
                    </Table>
                    <br />
                  </Card.Body>
                </Card>
              </div>
              <br />
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Examination;
