import React from "react";
import "./Admission.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

function Admission() {
  return (
    <div className="course-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Admission</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5" style={{ color: "#ec2328" }}>
              Admission Procedure
            </h2>

            <h4 style={{ color: "#ec2328" }}>Apply Offline</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              <ol>
                <li>
                  Download Application form by filling the required fields given
                  below.
                </li>
                <li>Fill the Application Form.</li>
                <li>
                  Submit the application form along with the Relevent Documents
                  and made payment by hand, to the following address:
                  <br />
                  The Admission Department & Counselling Department, Pt. Ram
                  Kedar Ram Kishore Tripathi Mahavidyalya, Rawaniya Paschim
                  Sultanpur - 227805 (INDIA), PH.: +91,9450766421
                </li>
              </ol>
              <form
                className="p-3 row"
                style={{ border: "solid #ec2328 1px", borderRadius: "10px" }}
              >
                <div class=" mb-1">
                  <label for="mobile" class="col-6 form-label">
                    Mobille Number
                  </label>
                  <input
                    type="phone"
                    class="form-control"
                    id="mobile"
                    aria-describedby="emailHelp"
                  />
                </div>

                <div class=" mb-1">
                  <label for="exampleInputEmail1" class="col-6 form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div class=" mb-1">
                  <label for="course" class="col-6 form-label">
                    Course
                  </label>
                  <select
                    class="form-select"
                    id="course"
                    aria-label="Default select example"
                  >
                    <option selected>Select</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div class=" mb-1">
                  <label for="applicant" class="col-6 form-label">
                    Applicant Type
                  </label>
                  <select
                    class="form-select"
                    id="applicant"
                    aria-label="Default select example"
                  >
                    <option selected>Select</option>
                    <option value="1">Indian/NRI</option>
                    <option value="2">International Candidate</option>
                  </select>
                </div>
                <br />

                <button type="submit" class="download-btn">
                  Download Form
                </button>
              </form>
            </p>
          </div>
          <div className="col-lg-6 ">
            <h2 className="mb-4 mb-lg-5" style={{ color: "#ec2328" }}>
              Admission Procedure
            </h2>

            <h4 style={{ color: "#ec2328" }}>Apply Online</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              <ol>
                <li>
                  Click on the apply online button below, it will redirect you
                  to admission application portal.
                </li>
                <li>Register yourself by filling required information.</li>
                <li>
                  Verify your email address by clicking on the link sent to your
                  email address.
                </li>
                <li>
                  Fill the Application Form online. Verify the details and
                  submit the form.
                </li>
                <li>Make payment to complete your application process.</li>
              </ol>

              <div className="d-flex">
                <div className="col-5 ms-4">
                  {" "}
                  <a href="" className="anchor">
                    <i class="fas fa-solid fa-download"></i>
                    Download Prospectus
                  </a>{" "}
                </div>

                <div className="col-5 ms-4">
                  {" "}
                  <a href="" className="anchor">
                    <i class="fas fa-solid fa-download"></i>
                    Apply Online
                  </a>{" "}
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Admission;
