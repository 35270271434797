import React from "react";
import "./Facilities.css";
import { Link } from "react-router-dom";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";

function SmartClass() {
  return (
    <div className="facility-page">
      <Header />
      <div className="container my-5 ">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">Smart Classroom</h2>
            <p style={{ textAlign: "justify" }}>
              Our college has individual and well-equipped smart classroom for
              each department. The smart classroom helps to renovate the
              traditional education system into modernized one. It provides
              eminence education to students through better concept formation,
              concept elaboration, improvement in reading skills and academic
              attainment.
            </p>
            <h3 className="mb-4 mb-lg-5"> Online Classes</h3>
            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              Our College is Planning to run Online classes form the new
              Academic Year 2020-21.
              <br />
              “An online class is a course conducted over the Internet. They are
              generally conducted through a learning management system, in which
              students can view their course syllabus and academic progress, as
              well as communicate with fellow students and their course
              instructor.”
            </p>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/smart-classroom-acm.jpg"
              className="img-fluid w-100 h-75"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SmartClass;
