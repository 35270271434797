import React from "react";
import "./Course.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";

function DelEd() {
  return (
    <div className="course-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Courses</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5" style={{ color: "#904018" }}>
              DIPLOMA IN ELEMENTRY EDUCATION (D.EL.ED)
            </h2>

            <h4 style={{ color: "#ec2328" }}>Course Overview</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              The(D.El.Ed ) BTC is a two year professional diploma programme of
              teacher education. It aims to prepare teachers for the elementary
              stage of education. The aim of elementary education is to fulfill
              the basic learning of all children in an inclusive school
              environment bridging social & gender gaps with the active
              participation of the community.
              <br />
              The elementary teacher education programme carries different
              nomenclatures such as BTC, JBT & D.Ed. (Diploma in
              Education).Henceforth; the nomenclature of the programme shall be
              same across from the date of admission to the programme.
              <br />
              The two-year D.El.Ed (BTC) diploma course is a necessary
              certificate for the appointment of primary teachers in a
              government primary schools in Uttar Pradesh.
              <br />
              Two year (full time) recognised by NCTE, Govt. of India. Approved
              by SCERT (State Council of Education Research and Training),
              Lucknow, U.P. State Government.
              <br />
              Duration: The BTC Programme shall be of duration of two academic
              years. The two academic year divided into four semesters .
            </p>

            <h4 style={{ color: "#ec2328" }}>Eligibility for D.El.Ed Course</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              Graduation Degree with at least 50 % marks. Reserved category
              candidates will get 5% relaxation in minimum qualifying marks.
              <br />
              OR
              <br />
              As per NCTE & U.P. Government Norms.
            </p>
            <h4 style={{ color: "#ec2328" }}>Admission Procedure</h4>
            <p>
              Admission shall be made on merit on the basis of marks obtained in
              the qualifying examination and / or in the entrance examination or
              any other selection process as per the policy of the State
              Government.
            </p>

            <h4 style={{ color: "#ec2328" }}>
              Course Fees D.El.Ed (2 Years) :
            </h4>
            <p>
              First Year --– 41,000/- (Fourty One Thousand Only
              <br />) Rs. 10,000/- Online Payment at the time of College
              Allotment ,<br />
              And 31,000/- After College allotment and Fees Paid at College
              Level)
              <br />
              Second Year --- 41,000/- (Fourty One Thousand Only)
              <br />
              Fees Paid at College Level)
              <br />
            </p>

            <h4 style={{ color: "#ec2328" }}>Total Seat :</h4>
            <p>TTotal - 100 seats in each academic year.</p>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/history.jpg"
              className="img-fluid h-25 w-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DelEd;
